import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap'
import { Button } from 'antd'

import Loader from 'react-loader-spinner';

import "antd/dist/antd.css"
import './Logout.css';
import Title from './Title';

class Logout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: false,
    }
  }

  submitForm = () => {
    this.setState({login: true})
    window.location.href = '/api/auth/logout'
  }

  componentDidMount(){
    this.setState({login: false})
    //remove all sessionstorage items
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('clientName');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('mail');
    sessionStorage.removeItem('x-api-key');
    
    localStorage.removeItem('userRole');
    localStorage.removeItem('code');
    localStorage.removeItem('sender');
    localStorage.removeItem('state');
    localStorage.removeItem('path');
    
  }

  render() {
    return (
      <Container fluid="true" className="screen-container">
        <div>
        <div className="header-container" >
          <Title title="Business Travel Compliance" subTitle="Additional Information Questionnaire" />
          <div className="layer">
          
          </div>
        </div>
        {this.state.loading ? (
        <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0"    
      }}
    >          
          <Loader type="Circles" color="#00BFFF" height={80} width={80}/>
    </div>) : (
          <>
              <div className="form-container">
                <Row className="plr-20">
                <Col sm="12" md={{ size: 12 }}>
                      <div className="buttons-container">
                      <Button type="primary" size="large" onClick={this.submitForm} className="action-button" htmlType="submit">Login</Button>&nbsp; &nbsp;
                      </div>
                </Col>
                </Row>
                </div>
                </>)}
          {this.state.login ?
            <Redirect push to={localStorage.getItem('path')}/>
          : null }
        </div>
      </Container>
    )
  }
}


export default Logout;
