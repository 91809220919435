import * as qs from 'query-string';
import uuid from 'react-uuid';

export const AuthService = {
  isAuthenticated: () => {
    return !!sessionStorage.getItem('token');
  },

  processExternalAuthentication: (tokenProcessCallback) => {
    if (sessionStorage.getItem('token')) {
      return;
    }
    const location_ = window.location;

  

    const parsed = qs.parse(location_.search);
    if (parsed.code) {
      localStorage.setItem('code', parsed.code);
      tokenProcessCallback(parsed.code);
      return null;
    }
    const authData = {
      response_type: 'code',
      client_id: process.env.REACT_APP_CLIENT_ID,
      audience: process.env.REACT_APP_AUDIENCE,
      redirect_uri: location_,
      scope: process.env.REACT_APP_SCOPE,
      state: uuid(),
      nonce: 'test',
    };

    localStorage.setItem('state', authData.state);

    window.location.href = process.env.REACT_APP_EXTERNAL_AUTH_URL + '?' + qs.stringify(authData);
    return null;
  },
};
