import * as actionTypes from './actionTypes';
import history from '../../features/routing/history';
import { makeRequest } from '../../features/communication/request';
import { useContext } from 'react';
import { BaseLayoutContext } from 'layouts/baseLayout/BaseLayoutProvider';
import { useEffect } from 'react';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userRole) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: {
      token,
      userRole,
    },
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    payload: {
      error: error,
    },
  };
};

export const logout = () => {
  localStorage.removeItem('code');
  sessionStorage.removeItem('token');
  localStorage.removeItem('userRole');
  if (history.location.pathname !== '/login') {
    localStorage.setItem('path', history.location.pathname + history.location.search);
    setTimeout(() => {
      history.push('/login');
      window.location.reload();
    }, 100);
  }
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};


export const authOFISLogin = (code) => {
  return (dispatch) => {
    dispatch(authStart());
    //console.log("Auth Code:", code);
    makeRequest({
      hostName: process.env.REACT_APP_BACKEND_API_V3,
      method: 'post',
      url: '/api/v3/auth',
      body: {
        code:code
      }
    }).then((response) => {
        //debugger;
      //console.log("Auth API Response:", JSON.stringify(response));

      const userRole = response.data.userRole;
      localStorage.setItem('userRole', userRole);
      
      localStorage.removeItem('code');
      const token = response.data.token;
      sessionStorage.setItem('token', token);
      
      const xapikey = response.data.xApiKey;
      sessionStorage.setItem('x-api-key', xapikey);
      //console.log("XApiKey:", xapikey);

      const mail = response.data.user.email;
      sessionStorage.setItem('mail', mail);
      //console.log("Mail:", mail);
            
      dispatch(authSuccess(code, userRole));
      dispatch(checkAuthTimeout(3600)); // automatic log out after one hour

      history.push(localStorage.getItem('path'));
    });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = sessionStorage.getItem('token');
    const userRole = localStorage.getItem('userRole');
    if (!token) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token, userRole));
    }
  };
};



export default function useAdmin(user) {

  const {setUserRole} = useContext(BaseLayoutContext)

  useEffect(() => {
    if(user)
    setUserRole(user)
  },[user])

  
}

